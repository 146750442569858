import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Headings, SecImg } from '../../Components/Component'
import { SecImgOne, SecImgTwo } from '../../img/AllImages'

const SynergisticRelationshipsSec = () => {
    return (
        <>
            <Headings

                UpperHeading="Integrated teamwork"
                LowerHeading="Synergistic Relationships"
                HeadingContent="Passing the baton to our clients for a great finale"
            />

            <section className='sectionCss__ secImg_one'>
                <Container>
                    <Row className='justify-content-end'>
                        <SecImg
                            secImgCol__="6"
                            secContentCol__="6"
                            secContentClass__="align-self-center secImgCss__"
                            secContent__={
                                <>
                                    <div className="secImgHeading">
                                        <h5>Success is not a Sprint, It’s a Relay Race</h5>
                                    </div>
                                    <div className="secImgContent">
                                        <p>Techizh team’s combined passion is to build and sustain a win-win relationship with each client we acquaint with. We learn, grow, celebrate, and lead together with our clients in a competitive world.</p>
                                    </div>
                                </>
                            }
                            secImgMain={(
                                <img src={SecImgOne} alt="" />
                            )}
                            secImgMainClass="d-block d-lg-none secImg"
                        />
                    </Row>
                </Container>
            </section>

            <section className='sectionCss__ secImg_two'>
                <Container>
                    <Row className='flex-row-reverse flex-lg-row'>
                        <SecImg
                            secImgCol__="6"
                            secContentCol__="6"
                            secContentClass__="align-self-center secImgCss__ "
                            secContent__={
                                <>
                                    <div className="secImgHeading">
                                        <h5>The Virtuous Circle of Trust</h5>
                                    </div>
                                    <div className="secImgContent">
                                        <p>Our greatest asset is the trust that our clients put in our services when they use our designed solutions to serve their clients. Hence, building a virtuous circle of trust that binds us all together. We are proud to have been serving leading industry names through our clients. Techizh — a link in the value chain for:</p>
                                    </div>
                                </>
                            }
                            secImgMain={(
                                <img src={SecImgTwo} alt="" />
                            )}
                            secImgMainClass="d-block d-lg-none secImg"
                        />
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default SynergisticRelationshipsSec