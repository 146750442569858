import React from 'react'
import CorporateSocialResponsibility from "../Pages/CorporateSocialResponsibility"
import SynergisticRelationships from "../Pages/SynergisticRelationships"
import GlobalCorporateAlliances from "../Pages/GlobalCorporateAlliances"
import TechnologyPartners from "../Pages/TechnologyPartners"
import AboutUs from "../Pages/AboutUs"
import PrivacyPolicy from "../Pages/PrivacyPolicy"
import TermsOfService from "../Pages/TermsOfService"
import OurTeam from "../Pages/OurTeam"

import Error from "../Pages/Error"

const Home = React.lazy(() => import('../Pages/Home'))
const ContactUs = React.lazy(() => import('../Pages/ContactUs'))
const Company = React.lazy(() => import('../Pages/Company'))
const Services = React.lazy(() => import('../Pages/Services'))

const routes = [

    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/contact-us', exact: true, name: 'Contact Us', component: ContactUs },
    { path: '/company', exact: true, name: 'Company', component: Company },
    { path: '/services', exact: true, name: 'Services', component: Services },
    { path: '/corporate-social-responsibility', exact: true, name: 'Corporate Social Responsibility', component: CorporateSocialResponsibility },
    { path: '/synergistic-relationships', exact: true, name: 'Synergistic Relationships', component: SynergisticRelationships },
    { path: '/global-corporate-alliances', exact: true, name: 'Global Corporate Alliances', component: GlobalCorporateAlliances },
    { path: '/technology-partners', exact: true, name: 'Technology Partners', component: TechnologyPartners },
    { path: '/about-us', exact: true, name: 'About Us', component: AboutUs },
    { path: '/privacy-policy', exact: true, name: 'Privacy Policy', component: PrivacyPolicy },
    { path: '/terms-of-services', exact: true, name: 'TERMS OF SERVICE', component: TermsOfService },
    { path: '/our-team', exact: true, name: 'Our Team', component: OurTeam },

    { path: '/404', exact: true, name: 'Error', component: Error },


]

export default routes