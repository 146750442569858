import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FaceBook, FooterLogo, Insta, Linkedin, Location, Phone, Tweeter } from '../img/AllImages';

const Footer = () => {
    const location = useLocation();
    // const history = useHistory();

    useEffect(() => {
        if (location.hash) {
            const section = document.querySelector(location.hash);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (location.pathname === '/services') {
            const sectionId = location.hash.replace("#", "");
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>
            <footer>
                <Container>
                    <Row>
                        <Col md={12} xl={5} className="mb-5 mb-xl-0">
                            <div className="footerLogo">
                                <img src={FooterLogo} alt="" />
                            </div>
                            <div className="footerLinkIcon d-none d-md-block">
                                <ul>
                                    <li><a href="https://www.facebook.com/techizh" target='_blank' rel="noreferrer"><img src={FaceBook} alt="" /></a></li>
                                    <li><a href="/"><img src={Tweeter} alt="" /></a></li>
                                    <li><a href="/"><img src={Insta} alt="" /></a></li>
                                    <li><a href="https://www.linkedin.com/in/tech-izh-7848452a9/" target='_blank' rel="noreferrer"><img src={Linkedin} alt="" /></a></li>
                                </ul>
                            </div>
                            <div className="footerLink d-none d-md-block">
                                <div className="footerLinkHeading"></div>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-services">Terms of Service</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><div className="copyRight"><span> Copyright © TECHIZH | All Rights Reserved </span></div></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} xl={3} className="mb-4 mb-md-0">
                            <div className="footerLink">
                                <div className="footerLinkHeading">
                                    <h5>Company</h5>
                                </div>
                                <ul>
                                    <li><Link to="/about-us">About us</Link></li>
                                    <li><Link to="/synergistic-relationships">Synergistic Relationships</Link></li>
                                    <li><Link to="/corporate-social-responsibility">Corporate Social Responsibility</Link></li>
                                    <li><Link to="/our-team">Certifications</Link></li>
                                    <li><Link to="/technology-partners">Technology Partners</Link></li>
                                    <li><Link to="/global-corporate-alliances">Global Corporate Alliances</Link></li>
                                </ul>
                            </div>
                            <div className="footerLink">
                                <div className="footerLinkHeading">
                                    <h5>Key Services</h5>
                                </div>
                                <ul>
                                    <li><Link to="/services#dataScience">Data Science</Link></li>
                                    <li><Link to="/services#artificialIntelligence">Artificial Intelligence</Link></li>
                                    <li><Link to="/services#internetofThings">Internet of Things (IoT)</Link></li>
                                    <li><Link to="/services#hardwareEngineering">Hardware Engineering</Link></li>
                                    <li><Link to="/services">View All Services</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} xl={3} className="footerLocation">
                            <div className="footerLink">
                                <div className="footerLinkHeading">
                                    <h5>Contact Information</h5>
                                </div>
                                <div className="canadaLocation">
                                    <div className="canadaLocationHeading">
                                        <h6>Canada</h6>
                                    </div>
                                    <div className="canadaLocationDetail">
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Location} alt="" />
                                            </span>
                                            <span> <a href="https://maps.google.com?q=2740+North+Park+Dr+%2330,+Brampton,+Ontario,+Canada+L6S+6E2" target="_blank" rel="noopener noreferrer">
                                                2740 North Park Dr #30
                                                Brampton, Ontario
                                                Canada
                                                L6S 6E2
                                            </a></span>
                                        </div>
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Phone} alt="" />
                                            </span>
                                            <span><a href="tel:+19059618755">+1 (905) 961-8755</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="canadaLocation">
                                    <div className="canadaLocationHeading">
                                        <h6>USA</h6>
                                    </div>
                                    <div className="canadaLocationDetail">
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Location} alt="" />
                                            </span>
                                            <span> <a href="https://maps.google.com?q=44663+Clover+Lane,+Temecula,+CA+92592" target="_blank" rel="noopener noreferrer">
                                                44663 Clover Lane, Temecula, CA 92592
                                            </a></span>
                                        </div>
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Phone} alt="" />
                                            </span>
                                            <span><a href="tel:+19059618755">+1 (905) 961-8755</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="canadaLocation">
                                    <div className="canadaLocationHeading">
                                        <h6>Pakistan</h6>
                                    </div>
                                    <div className="canadaLocationDetail">
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Location} alt="" />
                                            </span>
                                            <span> <a href="https://maps.google.com?q=887+N.I.C.H.S,+Khayaban+e+Jinnah+Road,+Lahore" target="_blank" rel="noopener noreferrer">
                                                887 N.I.C.H.S, Khayaban e Jinnah Road, Lahore
                                            </a></span>
                                        </div>
                                        <div className="canadaLocationDetailItem">
                                            <span>
                                                <img src={Phone} alt="" />
                                            </span>
                                            <span><a href="tel:+923214788099">+92 (321) 478-8099</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xl={5} className="d-block d-md-none mt-5">
                            <div className="footerLink">
                                <div className="footerLinkHeading"></div>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-services">Terms of Service</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                                <div className="footerLinkIcon">
                                    <ul>
                                        <li><Link to="/"><img src={FaceBook} alt="" /></Link></li>
                                        <li><Link to="/"><img src={Tweeter} alt="" /></Link></li>
                                        <li><Link to="/"><img src={Insta} alt="" /></Link></li>
                                        <li><Link to="/"><img src={Linkedin} alt="" /></Link></li>
                                    </ul>
                                </div>
                                <ul>
                                    <li>
                                        <div className="copyRight">
                                            <span> Copyright © TECHIZH | All Rights Reserved</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default Footer;
