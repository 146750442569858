import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { CardFlagSec, Headings } from '../../Components/Component'
import { BgSecTwo } from '../../img/AllImages'

const GlobalCorporateAlliancesSec = () => {
    return (
        <>
            <Headings
                UpperHeading="Together Towards Global Excellence"
                LowerHeading="Global Corporate Alliances"
                HeadingContent="Technological Collaboration: Bridging the Innovation Gap"
            />

            <section className='pb-4'>
                <Container>
                    <Row>
                        <Col md={8} className='mx-auto'>
                            <div className="changeHeading" style={{ textAlign: "center" }}>
                                <h5>Our Reach</h5>
                            </div>
                            <div className="secImgContent">
                                <p style={{ textAlign: "center" }}>Techizh corporate allies empower us in numerous ways, such as providing strategic insights, building partnership networks with key industry players and exposing our services and solutions to new markets.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="cardFlagImgSec">
                                <img src={BgSecTwo} alt="" />
                            </div>
                        </Col>
                        <CardFlagSec />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GlobalCorporateAlliancesSec