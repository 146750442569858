import React from 'react'
import Header from '../ui/Header'
import Footer from '../ui/Footer'
import OurTeamSec from './OurTeam/OurTeamSec'

const OurTeam = () => {
    return (
        <>
            <Header />
            <OurTeamSec />
            <Footer />
        </>
    )
}

export default OurTeam