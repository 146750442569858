import React from 'react'
import Header from '../ui/Header'
import Footer from '../ui/Footer'
import TermsOfServiceSec from './TermsOfService/TermsOfServiceSec'

const TermsOfService = () => {
    return (
        <>
            <Header />
            <TermsOfServiceSec />
            <Footer />
        </>
    )
}

export default TermsOfService