import React from 'react'
import { Headings, TwoHeadingCom } from '../../Components/Component'
import { Container, Row } from 'react-bootstrap'
import { SectionEight, SectionEleven, SectionNine, SectionTen, SectionTwelve } from '../../img/AllImages'

const PrivacyPolicySec = () => {
    return (
        <>
            <Headings
                UpperHeading="Confidentiality Assurance"
                LowerHeading="Privacy Policy"
                HeadingContent={
                    <span className='headingContentDiv'>
                        <span>Thank you for using Techizh.</span>
                        <span>At Techizh ("we", “us”, “our”, or the "Company"), we respect your privacy and are committed to protecting your personal data. This Privacy Policy describes and explains how we collect, use, disclose and share your data (“information”, “personal data”, “personal information”), that we receive from you (“user”, “users”) when you use our website or any of our software, IT, and AI solutions, development services, consultancy services and services (collectively, the "Services"). We are committed to protecting your privacy and complying with applicable data protection laws and regulations.</span>
                    </span>
                }
            />


            <section className='privacyPolicySetting'>
                <Container>
                    <Row className='flex-column-reverse flex-md-row flex-md-wrap'>
                        <TwoHeadingCom
                            TwoHeadingUpper="01"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>Information We Collect</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>We may or may not collect information about you when you use our website or Services. The information we collect may include any or all of the following:</p>
                                            <ul>
                                                <li>➤ Personal information you provide to us when you register, make a purchase, or otherwise use our Services, such as your name, email address, phone number, mailing address, billing information, and other contact details.</li>
                                                <li>➤ Payment information, such as credit card number or other payment account information. User credentials, such as usernames and passwords.</li>
                                                <li>➤ Demographic information such as your age, gender, and location.</li>
                                                <li>➤ Technical information such as your IP address, browser type, operating system and Information about your device, such as device type, device ID, and other device-specific information.</li>
                                                <li>➤ Cookies and similar technologies, which we use to collect technical, usage information and log data such as pages visited and the features you use on our website or applications, and other information about how you use our Services, personalize your experience, and analyze usage patterns. You can manage your cookie preferences through your browser settings.</li>
                                                <li>➤ AI-generated data such as facial recognition data, voice recognition data, and other biometric data.</li>
                                                <li>➤ Information provided by users when they interact with our customer support team or participate in surveys, contests, or promotions.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="twoHeadingImg secImg text-end">
                                        <img src={SectionEight} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section className='privacyPolicySetting'>
                <Container>
                    <Row className='flex-md-row-reverse flex-column-reverse'>
                        <TwoHeadingCom
                            TwoHeadingUpper="02"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>How We Use Information</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>We may use your personal data or information for the following purposes:</p>
                                            <ul>
                                                <li>➤ To provide, maintain and improve our Services.</li>
                                                <li>➤ To communicate with you about your account, updates to our Services or any </li>
                                                <li>➤ transactions you make through our Services, and customer support inquiries.</li>
                                                <li>➤ To personalize your experience and provide tailored content and recommendations.</li>
                                                <li>➤ To improve our Services and develop new products and services.</li>
                                                <li>➤ To conduct research and analysis to better understand our users and their needs.</li>
                                                <li>➤ To process payments and manage billing.</li>
                                                <li>➤ To prevent and detect fraud, unauthorized access, or other illegal activities.</li>
                                                <li>➤ To enforce our policies and protect our rights and interests.</li>
                                                <li>➤ To comply with applicable laws, regulations, legal obligations, such as responding to subpoenas, court orders, or other lawful requests for information.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="twoHeadingImg secImg">
                                        <img src={SectionNine} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section className='privacyPolicySetting'>
                <Container>
                    <Row className='flex-column-reverse flex-md-row flex-md-wrap'>
                        <TwoHeadingCom
                            TwoHeadingUpper="03"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>Your Rights and Choices</h3>
                                            <span>(Your Choices and Control)</span>
                                        </div>
                                        <div className="secContent">
                                            <p>You or user or users have certain rights or choices and control over the information we collect about you and how it is used, and users may or may not exercise the following rights by contacting us using the contact information provided at the end of this Privacy Policy, which include:</p>
                                            <ul>
                                                <li>➤ The right to access, request the correction or updating, or request the deletion of your personal data or information through your account settings or by contacting us.</li>
                                                <li>➤ The right to data portability, or receive a copy in a structured and machine-readable format of your personal data or information we hold about you.</li>
                                                <li>➤ The right to restrict or object or withdraw your consent to the processing of your personal data or information.</li>
                                                <li>➤ Opting out of receiving marketing communications from us.
                                                    Managing your cookie preferences through your browser settings.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="twoHeadingImg secImg text-end">
                                        <img src={SectionTen} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section className='privacyPolicySetting'>
                <Container>
                    <Row className='flex-md-row-reverse flex-column-reverse'>
                        <TwoHeadingCom
                            TwoHeadingUpper="04"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem twoHeadingItem__Set">
                                        <div className="secHeading">
                                            <h3>Information Retention and Security</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>We retain your information for as long as necessary to provide our Services or to fulfill the purposes outlined or described in this Privacy Policy, unless a longer retention period is required by city or district or county or state or international law or laws. We may also retain and use your information to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                                        </div>
                                    </div>
                                    <div className="twoHeadingItem">
                                        <div className="upperHeading">
                                            <h2>05</h2>
                                        </div>
                                        <div className="secHeading">
                                            <h3>Information Security</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>We take reasonable or appropriate technical and organizational measures to protect your information or personal data from unauthorized access, use, disclosure, alteration, or destruction. However, no security measure can provide absolute protection, and we cannot guarantee the security of your personal data.</p>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="twoHeadingImg secImg">
                                        <img src={SectionEleven} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section className='privacyPolicySetting'>
                <Container>
                    <Row className='flex-column-reverse flex-md-row flex-md-wrap row'>
                        <TwoHeadingCom
                            TwoHeadingUpper="06"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem twoHeadingItem__Set">
                                        <div className="secHeading">
                                            <h3>International Data Transfers</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>Personal information may be transferred to, and processed in, countries other than the country in which the user is located. We will take appropriate measures to ensure that such transfers comply with applicable data protection laws and regulations.</p>
                                        </div>
                                    </div>
                                    <div className="twoHeadingItem">
                                        <div className="upperHeading">
                                            <h2>07</h2>
                                        </div>
                                        <div className="secHeading">
                                            <h3>Changes to this Privacy Policy</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you or user or users about any material changes by posting the updated Privacy Policy on our website, or by email, or both, or by other means.</p>
                                            <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at [contact email].</p>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="twoHeadingImg secImg text-end">
                                        <img src={SectionTwelve} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default PrivacyPolicySec