import React from 'react'
import { Headings, SecImg, Slider } from '../../Components/Component'
import { Col, Container, Row } from 'react-bootstrap'
import { SectionFourteen } from '../../img/AllImages'

const OurTeamSec = () => {
    return (
        <>
            <Headings
                UpperHeading="MANAGEMENT TEAM"
                LowerHeading="Our Core Team"
                HeadingContent='People – our binding force, our competitive edge'
            />

            <section className='sectionCss__ secImg_fourteen'>
                <Container>
                    <Row>
                        <SecImg
                            secImgCol__="6"
                            secContentCol__="6"
                            secContentClass__="align-self-center secImgCss__ "
                            secContent__={
                                <>
                                    <div className="secImgHeading">
                                        <h5>The Exceptional Techizh Team</h5>
                                    </div>
                                    <div className="secImgContent">
                                        <p>The confidence and trust that Techizh clients put in our company is an outcome of the untiring efforts of our team members. The vision of Techizh leadership is to make team members own the company as their own. We believe that the true passion for work comes from within rather than by tempting a person with only monetary gains. The self-respect and satisfaction we feel in accomplishing a worthwhile task fuel our passion for the work.
                                            <br />Conflicts are inevitable in a team, but we turn conflicts into contests of differing points of view through which only the best ideas survive. This mindset to risk the individual glory for team performance is what makes this organization a family. Following are the brief descriptions of our core team members with this visionary approach: </p>
                                    </div>
                                </>
                            }
                            secImgMain={(
                                <img src={SectionFourteen} alt="" />
                            )}
                            secImgMainClass="d-block d-lg-none secImg"
                        />
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="clickSlider">
                                <Slider />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default OurTeamSec