import React from 'react'
import Header from '../ui/Header'
import Footer from '../ui/Footer'
import CorporateSocialResponsibilitySec from './CorporateSocialResponsibility/CorporateSocialResponsibilitySec'

const CorporateSocialResponsibility = () => {
    return (
        <>
            <Header />
            <CorporateSocialResponsibilitySec />
            <Footer />
        </>
    )
}

export default CorporateSocialResponsibility