import React from 'react'
import { Headings, SecImg } from '../../Components/Component'
import { Container, Row } from 'react-bootstrap'
import { HoverImg } from '../Data/Data'
import { SectionFour } from '../../img/AllImages'

const TechnologyPartnersSec = () => {
    return (
        <>
            <Headings
                UpperHeading="IT alliances"
                LowerHeading="Technology Partners"
                HeadingContent="Ready to benefit our clients with authentic technology solutions"
            />

            <section className='secImg_three'>
                <Container>
                    <Row className='flex-row-reverse'>
                        <SecImg
                            secImgCol__="12"
                            secContentCol__="12"
                            secContentClass__="align-self-center secImgCss__ hoverImgItemMain"
                            secImgCol__Lg="6"
                            secContentCol__Lg="6"
                            secContent__={
                                <>
                                    <div className="secImgHeading">
                                        <h5>Techizh Technology Partners</h5>
                                    </div>
                                    <div className="secImgContent">
                                        <p>Partnering with global technology provider companies empowers Techizh team members to deliver cutting-edge solutions to our clients.</p>
                                    </div>
                                    <div className="hoverImgItem">
                                        {HoverImg.map((item, key) => {
                                            return (
                                                <div key={key} className='hoverImg'>
                                                    <img src={item.hoverImgItem} alt="" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                            secImgMain={
                                <>
                                    <div className="hoverSecImg">
                                        <img src={SectionFour} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default TechnologyPartnersSec