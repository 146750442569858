import React from 'react'
import { Button } from '../../Components/Component'

const ErrorSec = () => {
    return (
        <>
            <div className="errorDiv">
                <div className="errorHeading">
                    <h1>404</h1>
                </div>
                <div className="errorHeading">
                    <h3>Not Found</h3>
                </div>
                <div className="errorContent">
                    <p>The resource requested could not DE found on this server!</p>
                </div>
                <div className="errorBtn">
                    <Button
                        buttonText="Back to Home Page"
                        BtnLink="/"
                    />
                </div>
            </div>
        </>
    )
}

export default ErrorSec