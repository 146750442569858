import React from 'react'
import Header from '../ui/Header'
import Footer from '../ui/Footer'
import TechnologyPartnersSec from './TechnologyPartners/TechnologyPartnersSec'

const TechnologyPartners = () => {
    return (
        <>
            <Header />
            <TechnologyPartnersSec />
            <Footer />
        </>
    )
}

export default TechnologyPartners