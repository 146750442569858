import React from 'react'
import GlobalCorporateAlliancesSec from './GlobalCorporateAlliances/GlobalCorporateAlliancesSec'
import Header from "../ui/Header"
import Footer from "../ui/Footer"

const GlobalCorporateAlliances = () => {
    return (
        <>
            <Header />
            <GlobalCorporateAlliancesSec />
            <Footer />
        </>
    )
}

export default GlobalCorporateAlliances