import React from 'react'
import SynergisticRelationshipsSec from './SynergisticRelationships/SynergisticRelationshipsSec'
import Header from "../ui/Header"
import Footer from "../ui/Footer"

const SynergisticRelationships = () => {
    return (
        <>
            <Header />
            <SynergisticRelationshipsSec />
            <Footer />
        </>
    )
}

export default SynergisticRelationships