import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { CorporateCard, Headings } from '../../Components/Component'

const CorporateSocialResponsibilitySec = () => {
    return (
        <>
            <Headings
                UpperHeading="Sustainable Impact"
                LowerHeading="Corporate Social Responsibility"
                HeadingContent="Enabling a digital ecosystem to help you build connections between people, departments and organizations"
            />

            <section>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="bgSec">
                                <div className="bgSecContent">
                                    <div className="bgSecHeading">
                                        <h3>The Reach of Our Corporate Social Responsibility</h3>
                                    </div>
                                    <div className="bgSecPara">
                                        <p>As a company focused on people, we are mindful of our social responsibility, and we fulfill it in its true spirit. Starting from our responsibilities towards our current and prospective team members, we extend our reach to the entire society with a series of initiatives.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <CorporateCard
                            corporateCardHeading="Equal Employment Opportunity"
                            corporateCardContent="We go to great lengths to encourage passionate, skilled, and eligible people who can prosper in our company culture. We offer equal opportunities to every eligible person, regardless of their background, gender, creed, race, and physical impairments."
                        />
                        <CorporateCard
                            corporateCardHeading="Health and Safety of Our Team"
                            corporateCardContent="We safeguard the health and safety of each team member while at work and afterwards. Our most popular initiatives include free health care, health awareness workshops, and promotion of a healthier lifestyle."
                        />
                        <CorporateCard
                            corporateCardHeading="Initiatives for Society"
                            corporateCardContent="We have established various education, health, and sports funds for underprivileged sectors of the society. To learn more about these initiatives or to be a part of such activities, you can contact us."
                        />
                        <CorporateCard
                            corporateCardHeading="Initiatives for Environment"
                            corporateCardContent="We manage our valuable resources systematically and aim for the optimal balance of achieving more with less in every business operation. This means using technology in newer, more efficient, and environmentally friendly ways with the least impact on the environment."
                        />
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CorporateSocialResponsibilitySec