import {
    ABTC,
    ArtificialIntelligence,
    Blog,
    CBC,
    Canada,
    DXC,
    DataScience,
    DesktopApplications,
    DigitalExperience,
    EmbeddedSystems,
    KPI,
    Loom,
    MobileApplications,
    OXY,
    Pakistan,
    SilkRoad,
    SystemsEngineering,
    TechCo,
    USA,
    WebApplications,
    ItemOne,
    ItemTwo,
    ItemThree,
    ItemFour,
    ItemFive,
    ItemSix,
    ItemSeven,
    ItemEight,
    ItemNine,
    ItemTen,
    ItemEleven,
    ItemTwelve,
    ItemThirteen,
    ItemFourteen,
    ItemFifteen,
    ItemSixteen,
    SliderItem4,
    SliderItem3,
    SliderItem2,
    SliderItem1,
    SliderItem5,
    SliderItem6,
    SliderItem7,
    SliderItem8,
    PowerPlatform,
    DynamicsCe,
    IOT,
    Cybersecurity,
} from "../../img/AllImages";

const CardSecData = [
    {
        id: 1,
        cardSecImg: DataScience,
        cardSecHeading: "Data Science",
        cardSecContent: "Data analysis, data visualization, machine learning model development, predictive analytics, data mining, big data management",
    },
    {
        id: 2,
        cardSecImg: ArtificialIntelligence,
        cardSecHeading: "Artificial Intelligence",
        cardSecContent: "Chatbot development, virtual assistant development, intelligent automation, image recognition, speech recognition, predictive maintenance, machine learning ",
    },
    {
        id: 3,
        cardSecImg: EmbeddedSystems,
        cardSecHeading: "Embedded Systems",
        cardSecContent: "Hardware design, firmware development, embedded software development, microcontroller programming, robotics and automation",
    },
    {
        id: 4,
        cardSecImg: SystemsEngineering,
        cardSecHeading: "Systems Engineering",
        cardSecContent: "System architecture design, requirement engineering, software integration, system testing and validation, system maintenance",
    },
    {
        id: 5,
        cardSecImg: MobileApplications,
        cardSecHeading: "Mobile Applications",
        cardSecContent: "iOS app development, Android app development, cross-platform app development, mobile app testing and validation.",
    },
    {
        id: 6,
        cardSecImg: WebApplications,
        cardSecHeading: "Web Applications",
        cardSecContent: "Web app development, e-commerce website development, custom web software development, web application testing and validation",
    },
    {
        id: 7,
        cardSecImg: DesktopApplications,
        cardSecHeading: "Desktop Applications",
        cardSecContent: "Windows app development, Mac app development, cross-platform desktop app development, desktop application testing and validation",
    },
    {
        id: 8,
        cardSecImg: DigitalExperience,
        cardSecHeading: "Digital Experience",
        cardSecContent: "UX/UI design, user research, prototyping and testing, front-end development, responsive design",
    },
    {
        id: 9,
        cardSecImg: Cybersecurity,
        cardSecHeading: "Cybersecurity",
        cardSecContent: "Our cybersecurity experts work tirelessly to help businesses protect their critical data, valuable assets and systems from cyber threats by designing and implementing comprehensive security solutions. We offer a range of services to help businesses identify and mitigate security risks, and ensure compliance with industry regulations.",
    },
    {
        id: 10,
        cardSecImg: IOT,
        cardSecHeading: "Internet of Things (IoT)",
        cardSecContent: "Our team specializes in developing IoT solutions that connect devices and custom intelligent systems to drive business value and transform customer experiences, enabling real-time data collection, analysis and smart decision making. We work with a variety of industries, including entertainment, hospitality, healthcare, transportation, and manufacturing, to help drive business value, transform customer experiences, optimize operations and improve outcomes. Our IoT services include:",
    },
    {
        id: 11,
        cardSecImg: DynamicsCe,
        cardSecHeading: "Dynamics CE",
        cardSecContent: "Dynamics 365 is a cloud-based suite of business applications that can help you optimize your operations, streamline your business processes, and improve customer engagement. Our team of experts can help you with everything from implementation to ongoing support and training.",
    },
    {
        id: 12,
        cardSecImg: PowerPlatform,
        cardSecHeading: "Power Platform",
        cardSecContent: "Microsoft Power Platform is a business platform designed to empower businesses with customized solutions that help all departments in an organization. Power Platform provides every employee with the necessary resources, from the CEO to the front-line workers. The platform comprises of four applications; Power BI, Power Apps, Power Automate, and Power Virtual Agents. It leverages the combined capabilities of Hyper Automation, Artificial Intelligence (AI), and Mixed Reality to automate business processes, improve data insight, connect integral systems, and help overhaul businesses. Our MS Power Platform consultants have years of experience leveraging the Power Platform to connect technologies across organizational ecosystems. We can help companies across industries to optimize and streamline their existing and new processes with Microsoft’s advanced business platform.",
    },
]

const CardFlagData = [
    {
        id: 1,
        cardFlagSecImg: Canada,
        cardFlagSecHeading: "Canada",
        cardFlagSecHeadingTwo: "Ontario",
        cardFlagSecContent: "Techizh and The AAAAAAAAA Experts partnered together to develop customized safety-critical embedded systems and provide Quality Assurance services to leading brands in the automotive industry.",
        cardFlagSecLinkText: "aaaaaaaaa.com",
        cardFlagSecLink: "/",
    },
    {
        id: 2,
        cardFlagSecImg: USA,
        cardFlagSecHeading: "USA",
        cardFlagSecHeadingTwo: "California",
        cardFlagSecContent: "CCCCCC Solution, a joint venture of Techizh and DDDD aims at providing holistic solutions comprised of RFID hardware design, middleware, software and Enterprise Application Development.",
        cardFlagSecLinkText: "dddd.com,  ccccccc-solution.com",
        cardFlagSecLink: "/",
    },
    {
        id: 3,
        cardFlagSecImg: Pakistan,
        cardFlagSecHeading: "Pakistan",
        cardFlagSecHeadingTwo: "Lahore",
        cardFlagSecContent: "Techizh and HOXBI are technology service partners that provide extended product development lifecycle solutions from concept, engineering design, and lean manufacturing, to prototyping, product testing, and logistics.",
        cardFlagSecLinkText: "hoxbi.pk",
        cardFlagSecLink: "/",
    },
    {
        id: 4,
        cardFlagSecImg: USA,
        cardFlagSecHeading: "USA",
        cardFlagSecHeadingTwo: "LAS VEGAS",
        cardFlagSecContent: "Techizh collaborates with CCCCCC to assist them in Engineering Services such as PCB Design, Fabrication, and Testing. We work together and bring about high-quality, scalable, and customizable solutions.",
        cardFlagSecLinkText: "cccccc.com",
        cardFlagSecLink: "cardFlagSecLinkText",
    },
    {
        id: 5,
        cardFlagSecImg: USA,
        cardFlagSecHeading: "USA",
        cardFlagSecHeadingTwo: "Rochester",
        cardFlagSecContent: "Techizh and EEEEEEEEEEEE together create innovative solutions for IOT, Rail industry and Remote Monitoring. We help customers maximize existing software investments and embrace innovation in a world of hybrid IT.",
        cardFlagSecLinkText: "eeeeeeeeeeee.com",
        cardFlagSecLink: "/",
    },
]

const HoverImg = [
    {
        id: 1,
        hoverImgItem: OXY,
    },
    {
        id: 2,
        hoverImgItem: ABTC,
    },
    {
        id: 3,
        hoverImgItem: DXC,
    },
    {
        id: 4,
        hoverImgItem: TechCo,
    },
    {
        id: 5,
        hoverImgItem: Blog,
    },
    {
        id: 6,
        hoverImgItem: Loom,
    },
    {
        id: 7,
        hoverImgItem: SilkRoad,
    },
    {
        id: 8,
        hoverImgItem: CBC,
    },
    {
        id: 9,
        hoverImgItem: KPI,
    },
]

const ListItems = [
    {
        id: 1,
        listItemsContent: "Deep expertise in a wide range of technologies whether they are current or emerging, and staying up-to-date on the latest industry trends to deliver real business value.",
    },
    {
        id: 2,
        listItemsContent: "Passionate team with a blend of experienced professionals, and energetic fresh graduates with 2 to 3 years of experience, empowered to work on what they love.",
    },
    {
        id: 3,
        listItemsContent: "High retention rate of world-class engineers that translates into knowledge retention and our clients reap the rewards of their investment for many years.",
    },
    {
        id: 4,
        listItemsContent: "Excellent client relationship management guaranteeing longer business relations and strong referrals as a trusted partner for businesses of all sizes.",
    },
    {
        id: 5,
        listItemsContent: "Strong emphasis on collaboration with clients, other industry experts and vendors to ensure that clients can stay ahead of the competition.",
    },
]

const ListItems1 = [
    {
        id: 1,
        listItemsContent: "Competitive and transparent pricing model without compromising on quality, to ensure our clients receive the best possible value for their investment.",
    },
    {
        id: 2,
        listItemsContent: "Ability to see challenges holistically about our clients’ business, their industries, and the regulating bodies of those industries, to provide sustainable solutions to these challenges.",
    },
    {
        id: 3,
        listItemsContent: "Consistent quality delivery, ongoing support and maintenance to ensure that our solutions continue to perform at their best.",
    },
    {
        id: 4,
        listItemsContent: "Exceptional customer service throughout every phase of the project, and we are always available to answer their questions and address their concerns.",
    },
]

const OurExpertices = [
    {
        id: 1,
        upperHeadingCon: "01",
        mainHeadingCon: "Data Science",
        contentPara: "We use advanced statistical models, analytics and machine learning algorithms to uncover, unlock and extract valuable insights and opportunities from your complex data sets that drive business growth. Our data science team specializes in the followings to help organizations make data-driven decisions.",
        unorderlistItem: (<ul>
            <li>➤ Big Data Management</li>
            <li>➤ Big Data Processing and Storage</li>
            <li>➤ Solutions</li>
            <li>➤ Data Collection</li>
            <li>➤ Data Cleaning</li>
            <li>➤ Data Transformation</li>
            <li>➤ Data Visualization</li>
            <li>➤ Data Analysis</li>
            <li>➤ Data Visualization</li>
            <li>➤ Data Mining</li>
            <li>➤ Data Extraction</li>
            <li>➤ Data Interpretation.</li>
        </ul>),
        imgExpertices: ItemOne,
        itemId: "dataScience"
    },
    {
        id: 2,
        upperHeadingCon: "02",
        mainHeadingCon: "Artificial Intelligence",
        contentPara: "We keep eye on the latest advancements to build custom AI solutions (predictive intelligent systems) for businesses that can reason, learn, and interact like humans but in a non-traditional way. For data-driven decisions, automating processes and improving business operations, our AI team leverages the following expertise to help businesses automate routine tasks, streamline operations, optimize workflows, and improve customer experiences.",
        unorderlistItem: (<ul>
            <li>➤ Chatbot and Virtual Assistant Development</li>
            <li>➤ Predictive Analytics, Predictive Modeling and Predictive Maintenance</li>
            <li>➤ Machine Learning Algorithms Development and Implementation</li>
            <li>➤ Machine Learning Model Development, Deep Learning & Reinforcement Learning</li>
            <li>➤ Intelligent Automation</li>
            <li>➤ Computer Vision, Image Recognition and Speech Recognition</li>
            <li>➤ Natural Language Processing (NLP)</li>
            <li>➤ Robotic Process Automation (RPA)</li>
            <li>➤ Recommendation Systems</li>
            <li>➤  Anomaly Detection and Prevention</li>
        </ul>),
        imgExpertices: ItemTwo,
        itemId: "artificialIntelligence"
    },
    {
        id: 3,
        upperHeadingCon: "03",
        mainHeadingCon: "Embedded Systems",
        contentPara: "Our embedded systems engineers specialize in developing custom hardware and software solutions for a variety of applications, including automotive, healthcare, aerospace and industrial. We have extensive experience in designing and implementing complex systems that operate in real-time, with high reliability, efficiency, performance and cost-effectiveness. Our expertise include:",
        unorderlistItem: (<ul>
            <li>➤ Hardware Design</li>
            <li>➤ Firmware Development</li>
            <li>➤ Embedded Software Development</li>
            <li>➤ Microcontroller Programming</li>
            <li>➤ IoT Devices Development</li>
            <li>➤ Real-time Systems Development</li>
            <li>➤ Sensor Integration and Data Acquisition</li>
            <li>➤ Robotics and Automation</li>
        </ul>),
        imgExpertices: ItemThree,
        itemId: ""
    },
    {
        id: 4,
        upperHeadingCon: "04",
        mainHeadingCon: "Systems Engineering",
        contentPara: "Our systems engineering team is dedicated to design, implement and optimize complex systems that are tailored to meet the performance, cost, and reliability requirements of each client we work with. We follow a rigorous development process that includes requirements analysis, design, implementation, testing, and deployment to ensure that our solutions meet the highest standards of quality and reliability. Our systems engineers have expertise in:",
        unorderlistItem: (<ul>
            <li>➤ System Architecture Design and Optimization</li>
            <li>➤ Requirement Engineering</li>
            <li>➤ Software Integration</li>
            <li>➤ System Requirements Analysis and Definition</li>
            <li>➤ System Testing and Validation</li>
            <li>➤ Performance Tuning and Optimization</li>
            <li>➤ System Maintenance and Support</li>
        </ul>),
        imgExpertices: ItemFour,
        itemId: ""
    },
    {
        id: 5,
        upperHeadingCon: "05",
        mainHeadingCon: "Cybersecurity",
        contentPara: "Our cybersecurity experts work tirelessly to help businesses protect their critical data, valuable assets and systems from cyber threats by designing and implementing comprehensive security solutions. We offer a range of services to help businesses identify and mitigate security risks, and ensure compliance with industry regulations.",
        unorderlistItem: (<ul>
            <li>➤ Network Security and Monitoring</li>
            <li>➤ Vulnerability Assessment</li>
            <li>➤ Penetration Testing</li>
            <li>➤ Threat Detection and Response</li>
            <li>➤ Security Compliance</li>
            <li>➤ Application Security Testing and Code Review</li>
            <li>➤ Risk Assessment and Management</li>
            <li>➤ Identity and Access Management</li>
            <li>➤ Incident Response and Disaster Recovery</li>
            <li>➤ Cloud Security and Compliance</li>
        </ul>),
        imgExpertices: ItemFive,
        itemId: ""
    },
    {
        id: 6,
        upperHeadingCon: "06",
        mainHeadingCon: "Internet of Things (IoT)",
        contentPara: "Our team specializes in developing IoT solutions that connect devices and custom intelligent systems to drive business value and transform customer experiences, enabling real-time data collection, analysis and smart decision making. We work with a variety of industries, including entertainment, hospitality, healthcare, transportation, and manufacturing, to help drive business value, transform customer experiences, optimize operations and improve outcomes. Our IoT services include:",
        unorderlistItem: (<ul>
            <li>➤ IoT Platform Development and Customization</li>
            <li>➤ IoT Devices Development, Integration and Management</li>
            <li>➤ IoT Analytics and Visualization</li>
            <li>➤ IoT Security and Privacy</li>
            <li>➤ IoT Cloud Solutions</li>
            <li>➤ IoT Maintenance and Support</li>
        </ul>),
        imgExpertices: ItemSix,
        itemId: "internetofThings"
    },
    {
        id: 7,
        upperHeadingCon: "07",
        mainHeadingCon: "Hardware Engineering",
        contentPara: "Our hardware engineering team designs, develops and optimizes custom hardware solutions for a variety of applications that meet the specific needs and requirements of each organization we work with, whether it's for a consumer product or an enterprise-grade device. We have extensive experience in the following which allow us to deliver high-quality solutions that meet the highest standards of performance and reliability.",
        unorderlistItem: (<ul>
            <li>➤ Electronic Design and Optimization</li>
            <li>➤ Circuit Design and Layout</li>
            <li>➤ PCB Layout, Design and Fabrication</li>
            <li>➤ Prototype Development and Testing</li>
            <li>➤ product Development, Manufacturing and Manufacturing Support</li>
            <li>➤ Microcontroller Programming</li>
            <li>➤ Electromagnetic Compatibility (EMC) Testing and Certification</li>
        </ul>),
        imgExpertices: ItemSeven,
        itemId: "hardwareEngineering"
    },
    {
        id: 8,
        upperHeadingCon: "08",
        mainHeadingCon: "Quality Assurance",
        contentPara: "Our QA experts are committed that every solution we provide meets the quality and reliability requirements. We follow a rigorous testing process to ensure that our solutions meet the unique needs of each client we work with, and perform as expected or beyond that. Our QA expertise include:",
        unorderlistItem: (<ul>
            <li>➤ Functional Testing, Performance Testing, Load Testing, Security Testing and Test Automation</li>
            <li>➤ Quality Control and Assurance</li>
            <li>➤ Processes Development and Optimization</li>
            <li>➤ Quality Management</li>
            <li>➤ Software Auditing</li>
            <li>➤ Continuous Integration and Continuous Delivery (CI/CD)</li>
            <li>➤ User Acceptance Testing (UAT)</li>
        </ul>),
        imgExpertices: ItemEight,
        itemId: ""
    },
    {
        id: 9,
        upperHeadingCon: "09",
        mainHeadingCon: "Digital Experience",
        contentPara: "Our digital experience team specializes in creating comprehensive, immersive and engaging digital experiences that attract and retain customers. We offer a range of services to help businesses build stronger connections with their audience and stand out in a crowded marketplace. Our digital experience services include:",
        unorderlistItem: (<ul>
            <li>➤ UX/UI Design</li>
            <li>➤ User Research</li>
            <li>➤ Prototyping and Testing</li>
            <li>➤ Front-end Development and Design Responsiveness</li>
            <li>➤ Web Design and Development</li>
            <li>➤ Mobile App Design and Development</li>
            <li>➤ E-commerce Solutions</li>
            <li>➤ Digital Marketing and Content Creation</li>
            <li>➤ Content Management Systems (CMS) Development & Customization</li>
            <li>➤ Digital Marketing and SEO Optimization </li>
        </ul>),
        imgExpertices: ItemNine,
        itemId: ""
    },
    {
        id: 10,
        upperHeadingCon: "10",
        mainHeadingCon: "Enterprise Applications",
        contentPara: "Our enterprise applications team helps to optimize workflows and efficiently streamline business operations of each of our clients we work with. We have extensive experience in developing high quality and reliable custom software solutions for a variety of industries, including SMEs, healthcare, finance, and manufacturing, and our EA team has following expertise to ensure that our solutions integrate seamlessly with your existing systems and infrastructure.",
        unorderlistItem: (<ul>
            <li>➤ Enterprise Resource Planning (ERP) Systems Development and Customization</li>
            <li>➤ Customer Relationship Management (CRM) Systems Development & Customization</li>
            <li>➤ Supply Chain Management (SCM) Systems Development and Customization</li>
            <li>➤ Human Resources Management Systems (HRMS) Development & Customization</li>
            <li>➤ Business Intelligence (BI) and Analytics Systems Development & Customization</li>
            <li>➤ Project and Portfolio Management (PPM) Systems Development & Customization</li>
            <li>➤ Collaboration and Communication Tools</li>
            <li>➤ Custom Enterprise Software Development</li>
            <li>➤ Enterprise Mobility Solutions</li>
            <li>➤ Enterprise System Integration</li>
            <li>➤ Enterprise Software Support and Maintenance</li>
        </ul>),
        imgExpertices: ItemTen,
        itemId: ""
    },
    {
        id: 11,
        upperHeadingCon: "11",
        mainHeadingCon: "Mobile Applications",
        contentPara: "Our mobile application developers specialize in developing high-quality, engaging mobile applications for both iOS and Android devices. We work closely with businesses to understand their requirements, needs and goals, and we use the latest mobile technologies to provide solutions and deliver tangible business results with seamless user experience that stand out in a crowded marketplace. Our mobile application services include:",
        unorderlistItem: (<ul>
            <li>➤ iOS and Android App Development</li>
            <li>➤ Hybrid App Development using frameworks like React Native & Flutter</li>
            <li>➤ Mobile App UX and UI Design</li>
            <li>➤ App Testing and Debugging</li>
            <li>➤ App Maintenance and Support</li>
            <li>➤ App Store Optimization and Marketing</li>
        </ul>),
        imgExpertices: ItemEleven,
        itemId: ""
    },
    {
        id: 12,
        upperHeadingCon: "12",
        mainHeadingCon: "Web Applications",
        contentPara: "Our web applications team designs and develops high-quality web applications that meet the unique needs of each client we work with. We have extensive experience in a variety of modern web technologies to build custom, scalable and secure web applications that deliver an intuitively seamless, functional and aesthetically pleasing user experience. Our web application experience includes:",
        unorderlistItem: (<ul>
            <li>➤Web Design and UX/UI Design</li>
            <li>➤Web App Development</li>
            <li>➤E-commerce Website Development</li>
            <li>➤Custom Web Software Development</li>
            <li>➤Web Application Testing and Validation</li>
            <li>➤Web Hosting and Server Maintenance</li>
            <li>➤Web Analytics and Optimization</li>
        </ul>),
        imgExpertices: ItemTwelve,
        itemId: ""
    },
    {
        id: 13,
        upperHeadingCon: "13",
        mainHeadingCon: "Desktop Applications",
        contentPara: "Our desktop application developers build custom desktop applications that meet your specific needs and requirements, whether it's for a standalone application or an enterprise-grade system. We are experienced in developing powerful and intuitive desktop applications for a variety of platforms, including Windows and Mac OS that help businesses automate processes and optimize their operations. Our desktop expertise include:",
        unorderlistItem: (<ul>
            <li>➤ Windows and Mac App Development</li>
            <li>➤ Cross-platform Desktop Application Development using</li>
            <li>➤ technologies like Electron or Qt Desktop Application Testing and Validation</li>
            <li>➤ Legacy Application Modernization</li>
            <li>➤ Desktop Application Maintenance and Support</li>
        </ul>),
        imgExpertices: ItemThirteen,
        itemId: ""
    },
    {
        id: 14,
        upperHeadingCon: "14",
        mainHeadingCon: "Electronics & Communication",
        contentPara: "We offer comprehensive electronics and communication solutions, including design, development, and testing of hardware and software for a variety of applications that enable seamless and secure data transfer across a range of devices and applications, and allows businesses to connect with their customers and employees in real-time, improving overall communication efficiency and effectiveness. Our Electronics and Communication Engineering (ECE) expertise include:",
        unorderlistItem: (<ul>
            <li>➤ Analog and Digital Circuit Design and Development</li>
            <li>➤ Hardware Prototyping</li>
            <li>➤ Signal Processing and Analysis</li>
            <li>➤ RF System Design</li>
            <li>➤ PCB Design and Layout</li>
            <li>➤ FPGA and ASIC Design</li>
            <li>➤ Wireless Data Communication Protocol Development and Implementation</li>
            <li>➤ Antenna Design</li>
            <li>➤ Communication Network Design and Optimization</li>
            <li>➤ Satellite Communication</li>
        </ul>),
        imgExpertices: ItemFourteen,
        itemId: ""
    },
    {
        id: 15,
        upperHeadingCon: "15",
        mainHeadingCon: "Dynamics CE",
        contentPara: "Dynamics 365 is a cloud-based suite of business applications that can help you optimize your operations, streamline your business processes, and improve customer engagement. Our team of experts can help you with everything from implementation to ongoing support and training.",
        unorderlistItem: (<ul>
            <li>➤ Azure is Microsoft's cloud computing platform that enables you to modernize your IT infrastructure, improve scalability and performance, and reduce costs. We have the expertise to ensure that your Azure implementation is optimized for your unique business needs.</li>
            <li>➤ Modern Workplace is all about empowering your workforce with the tools they need to collaborate, communicate, and be productive from anywhere, at any time. Our consultancy can help you implement tools such as Microsoft Teams, SharePoint, and OneDrive to create a seamless and productive digital workplace.</li>
            <li>➤ Finally, Power BI is a business intelligence and data analytics tool that can help you gain deep insights into your data and make informed business decisions. Our team can assist with everything from data modeling and visualization to dashboard creation and reporting.</li>
            <li>➤ At our consultancy, we believe in taking a holistic approach to digital transformation. We work with our clients to create customized solutions that are tailored to their unique business needs. Our team has extensive experience in a variety of industries, and we pride ourselves on delivering results-driven solutions that help our clients stay ahead of the curve.</li>
            <li>➤ Partner with us to navigate the complex world of digital transformation with confidence. We'll help you unlock the full potential of your business and ensure that you stay ahead of the competition.</li>
        </ul>),
        imgExpertices: ItemFifteen,
        itemId: ""
    },
    {
        id: 16,
        upperHeadingCon: "16",
        mainHeadingCon: "Power Platform",
        contentPara: "Microsoft Power Platform is a business platform designed to empower businesses with customized solutions that help all departments in an organization. Power Platform provides every employee with the necessary resources, from the CEO to the front-line workers. The platform comprises of four applications; Power BI, Power Apps, Power Automate, and Power Virtual Agents. It leverages the combined capabilities of Hyper Automation, Artificial Intelligence (AI), and Mixed Reality to automate business processes, improve data insight, connect integral systems, and help overhaul businesses. Our MS Power Platform consultants have years of experience leveraging the Power Platform to connect technologies across organizational ecosystems. We can help companies across industries to optimize and streamline their existing and new processes with Microsoft’s advanced business platform.",
        unorderlistItem: (<ul>
            <li>➤ Data-driven insights that enable companies to make informed, reliable business decisions and measure KPIs. POWER APPS</li>
            <li>➤ Automated business processes with device-agnostic, low code business applications that save time and money. POWER AUTOMATE</li>
            <li>➤ Advanced, user-friendly tools to integrate Microsoft and non-Microsoft systems for end-to-end business process automation. POWER VIRTUAL AGENTS</li>
            <li>➤ Easy-to-use, customizable, no-code chatbots for multiple scenarios and seamless integrations with Microsoft technology stack.</li>
        </ul>),
        imgExpertices: ItemSixteen,
        itemId: ""
    },
]

const FaqsData = [
    {
        id: 1,
        faqItemUpperHeading: "03",
        faqItemLowerHeading: "Privacy and Data Protection",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 2,
        faqItemUpperHeading: "04",
        faqItemLowerHeading: "Confidentiality",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 3,
        faqItemUpperHeading: "05",
        faqItemLowerHeading: "Fees and Payment",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 4,
        faqItemUpperHeading: "06",
        faqItemLowerHeading: "Liability & Disclaimers",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 5,
        faqItemUpperHeading: "07",
        faqItemLowerHeading: "Limitations of Liability",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 6,
        faqItemUpperHeading: "08",
        faqItemLowerHeading: "Termination & Suspension",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 7,
        faqItemUpperHeading: "09",
        faqItemLowerHeading: "Termination",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 8,
        faqItemUpperHeading: "10",
        faqItemLowerHeading: "Governing Law & Jurisdiction",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 9,
        faqItemUpperHeading: "11",
        faqItemLowerHeading: "Governing Law",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 10,
        faqItemUpperHeading: "12",
        faqItemLowerHeading: "Dispute Resolution",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 11,
        faqItemUpperHeading: "13",
        faqItemLowerHeading: "Indemnification",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 12,
        faqItemUpperHeading: "14",
        faqItemLowerHeading: "Modifications to the Terms",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
]

const FaqsData1 = [
    {
        id: 13,
        faqItemUpperHeading: "15",
        faqItemLowerHeading: "Entire Agreement",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 14,
        faqItemUpperHeading: "16",
        faqItemLowerHeading: "Waiver",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 15,
        faqItemUpperHeading: "17",
        faqItemLowerHeading: "Severability",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 16,
        faqItemUpperHeading: "18",
        faqItemLowerHeading: "Assignment",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 17,
        faqItemUpperHeading: "19",
        faqItemLowerHeading: "Account Registration",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 18,
        faqItemUpperHeading: "20",
        faqItemLowerHeading: "Payment",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 19,
        faqItemUpperHeading: "21",
        faqItemLowerHeading: "Warranties",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 20,
        faqItemUpperHeading: "22",
        faqItemLowerHeading: "Disclaimer of Warranties",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 21,
        faqItemUpperHeading: "23",
        faqItemLowerHeading: "Disclaimer",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 22,
        faqItemUpperHeading: "24",
        faqItemLowerHeading: "Feedback",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 23,
        faqItemUpperHeading: "25",
        faqItemLowerHeading: "Third-Party Content",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
    {
        id: 24,
        faqItemUpperHeading: "26",
        faqItemLowerHeading: "Miscellaneous",
        faqItemContent: (
            <>
                <p>You agree to indemnify and hold us and our affiliates, officers, directors, employees, agents, and licensors harmless from and against any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising from or out of or in connection with your use of our Services, your breach or violation of these Terms, or your violation of any laws or regulations, or your violation of any third-party right.</p>
                <p>By using our Services, you agree to comply with all applicable laws and regulations, and to use the Services solely for lawful purposes. You are solely responsible for your use of the Services, and you agree to indemnify and hold us harmless for any claims arising from your use of the Services in violation of these Terms of Service.</p>
            </>
        )
    },
]

const SliderData = [
    {
        id: 1,
        sliderImg: SliderItem1,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem1,
    },
    {
        id: 2,
        sliderImg: SliderItem2,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem2,
    },
    {
        id: 3,
        sliderImg: SliderItem3,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem3,
    },
    {
        id: 4,
        sliderImg: SliderItem4,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem4,
    },
    {
        id: 5,
        sliderImg: SliderItem5,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem5,
    },
    {
        id: 6,
        sliderImg: SliderItem6,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem6,
    },
    {
        id: 7,
        sliderImg: SliderItem7,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem7,
    },
    {
        id: 8,
        sliderImg: SliderItem8,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem8,
    },
    {
        id: 9,
        sliderImg: SliderItem1,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem1,
    },
    {
        id: 10,
        sliderImg: SliderItem2,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem2,
    },
    {
        id: 11,
        sliderImg: SliderItem3,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem3,
    },
    {
        id: 12,
        sliderImg: SliderItem4,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem4,
    },
    {
        id: 13,
        sliderImg: SliderItem5,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem5,
    },
    {
        id: 14,
        sliderImg: SliderItem6,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem6,
    },
    {
        id: 15,
        sliderImg: SliderItem7,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem7,
    },
    {
        id: 16,
        sliderImg: SliderItem8,
        title: 'Zaman Gondal',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend magna sit amet justo vehicula, vel varius ligula ultricies. Integer varius, dui ac tristique faucibus, dolor lorem euismod magna, vitae eleifend purus est at dui. Maecenas ultricies nisi sit amet ligula placerat, et tincidunt nunc tempus. Sed in consectetur justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Morbi et fringilla ex. Fusce in sollicitudin neque, sed cursus ipsum. Cras suscipit dapibus pharetra. Aliquam id aliquam tellus. Etiam sed justo sed metus facilisis blandit.',
        sliderItemMainDetail: (
            <>
                <span>“Coming together is a beginning; keeping together is progress; working together is success” - <b>Edward Everett Hale</b></span>
            </>
        ),
        sliderItemImg: SliderItem8,
    },
]

export {
    CardSecData,
    CardFlagData,
    HoverImg,
    ListItems,
    ListItems1,
    OurExpertices,
    FaqsData,
    FaqsData1,
    SliderData,
}