import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, Headings, TwoHeadingCom } from '../../Components/Component'
import { InfoGraph, SectionFive, SectionSeven, SectionSix } from '../../img/AllImages'
import { ListItems, ListItems1 } from '../Data/Data'

const AboutUsSec = () => {
    return (
        <>
            <Headings
                UpperHeading="KNOW ABOUT US"
                LowerHeading="Our Company"
                HeadingContent="At Techizh, we have expertise in a wide range of technologies, including:"
            />

            <section>
                <Container>
                    <Row className='flex-column-reverse flex-md-row flex-md-wrap'>
                        <TwoHeadingCom
                            TwoHeadingUpper="Who We Are?"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>About Us</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>“Solutions, Services and Relationships to help our clients stay ahead of the competition”</p>
                                        </div>
                                    </div>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>What we do?</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>Techizh is a technology consulting and service providing firm that uses technology to enrich people’s lives, drive business growth and create value for our clients. With a team of experienced data scientists, engineers, software developers, IT specialists, and AI experts, we are passionate to offer data insights, technology excellence, efficient management, business intelligence, industry knowledge, aesthetics, and vision-all joined together-in one neat package.</p>
                                            <p>We as Techizh are committed to maximize your potential with our expertise in Embedded Systems, Custom Hardware Design, Systems Engineering, Cybersecurity, IoT, Electronics and Communication, Data Science, Machine Learning, Artificial Intelligence, Web Applications, Mobile Applications, Desktop Applications, and end-to-end automation.</p>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="hoverSecImg secImg">
                                        <img src={SectionFive} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row className='flex-md-row-reverse flex-column-reverse'>
                        <TwoHeadingCom
                            TwoHeadingUpper=""
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>Our History</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>Founded in 2016, Techizh has grown to become a dependable provider of software, IT, and AI solutions. From our humble beginnings as a small startup, we have evolved into a mature company with a strong track record of success. We have built a reputation for delivering high-quality solutions on time and on budget, and we value the long-term relationships we have established with our clients.</p>
                                        </div>
                                    </div>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>Our Mission</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>Techizh mission is to help businesses of all sizes thrive in a rapidly-changing digital landscape to improve their operations, increase efficiency, drive growth and enable them to achieve their goals. We are dedicated to delivering exceptional value and the highest quality of service to our clients by understanding their unique challenges, developing customized solutions that address their specific needs and striving to exceed their expectations at every opportunity.</p>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={
                                <>
                                    <div className="hoverSecImg secImg">
                                        <img src={SectionSix} alt="" />
                                    </div>
                                </>
                            }
                        />
                    </Row>
                </Container>
            </section>

            <section>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="secHeadingDiv">
                                <div className="upperHeading">
                                    <h2>Why US?</h2>
                                </div>
                                <div className="secHeading">
                                    <h3>Our 7 Values</h3>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="infoGraph">
                                <InfoGraph />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='bgColorSec'>
                <Container>
                    <Row className='flex-column-reverse flex-md-row flex-md-wrap'>
                        <TwoHeadingCom
                            TwoHeadingUpper="Responsibility"
                            TwoHeadingContent={
                                <>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>Our Commitment</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>At Techizh, we are committed to staying at the forefront of our industry by investing in the latest technologies and staying up-to-date with industry trends. We believe that this commitment to innovation and excellence is what sets us apart from other software, IT, and AI solutions providers, and we are confident that we can help our clients achieve their business goals in the best possible manner.</p>
                                            <p>Thank you for considering Techizh as your partner in technology. We look forward to the opportunity to work with you and help your business thrive.</p>
                                        </div>
                                    </div>
                                    <div className="twoHeadingItem">
                                        <div className="secHeading">
                                            <h3>Why is it all about people?</h3>
                                        </div>
                                        <div className="secContent">
                                            <p>In this fast-paced business world, every company has some driving force, a goal and a notion of success. In Techizh realm, it all revolves around people-One passionate team, many satisfied customers.</p>
                                            <p>The driving force behind our every project comes from the people of our team who are passionate to solve clients’ toughest business challenges. The goal is to enrich our clients’ business with consistent quality that is within budget and the planned schedule. Our key success metric is time management and comprehensive processes, resulting in delightful experiences.</p>
                                        </div>
                                    </div>
                                </>
                            }
                            TwoHeadingContentImg={(
                                <div className="hoverSecImg secImg d-block d-lg-none">
                                    <img src={SectionSeven} alt="" />
                                </div>
                            )}
                        />
                    </Row>
                </Container>
            </section>

            <section className='sectiongetinTouch'>
                <Container>
                    <Row>
                        <Col md={8} className='mx-auto'>
                            <div className="secHeadingDiv">
                                <div className="upperHeading">
                                    <h2>Who We Are?</h2>
                                </div>
                                <div className="secHeading">
                                    <h3>Our Competitive Edge</h3>
                                </div>
                                <div className="secContent">
                                    <p>At Techizh, being a mature technology company, our commitment to excellence and innovation sets us apart from the competition, and our clients trust us to achieve their business objectives Here are some of the key factors that give us a competitive edge:</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="listItem secContent">
                                <ul>
                                    {ListItems.map((item, key) => {
                                        return (
                                            <li key={key}>{item.listItemsContent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="listItem secContent">
                                <ul>
                                    {ListItems1.map((item, key) => {
                                        return (
                                            <li key={key}>{item.listItemsContent}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Col>
                        <Col md={9} className='mx-auto'>
                            <div className="secHeadingDiv">
                                <div className="secContent">
                                    <p style={{ textTransform: "capitalize" }}>Contact us today to turn your vision into reality and let us help you innovate, compete, thrive and take your business to the next level in 2023.</p>
                                </div>
                                <div className="secBtn">
                                    <Button
                                        buttonText="Get In Touch"
                                        BtnLink="/contact-us"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default AboutUsSec